import { cssBundleHref } from '@remix-run/css-bundle';
import { json, type LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  isRouteErrorResponse,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import type { ReactNode } from 'react';
import { useState } from 'react';
import type { Socket } from 'socket.io-client';
import { useDarkMode } from 'usehooks-ts';

import styles from '~/styles/styles.css';

import { AuthProvider } from './contexts/auth';
import { SocketProvider } from './contexts/socket';
import { getUserSession } from './services/sessions.server';
import { getEnv } from './utils/env.server';

export const links: LinksFunction = () => [
{
  rel: 'stylesheet',
  href: styles
},
...(cssBundleHref ? [{ rel: 'stylesheet', href: cssBundleHref }] : [])];


export const loader = async ({ request }: LoaderFunctionArgs) => {
  const userSession = await getUserSession(request);

  return json({
    ENV: getEnv(),
    isLeaderAuthenticated: userSession?.isLeader || false,
    user: userSession
  });
};

const App = () => {
  const { isLeaderAuthenticated, user, ENV } = useLoaderData<typeof loader>();
  const [socket] = useState<Socket>();
  const { isDarkMode } = useDarkMode({
    defaultValue: true,
    initializeWithValue: false,
    localStorageKey: 'dark-mode'
  });

  // useEffect(() => {
  //   const socket = io(ENV?.SOCKETS_URL ?? '');
  //   setSocket(socket);
  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  const authContext = {
    isLeaderAuthenticated,
    leaderId: user?.profile?._id,
    avatar: user?.profile?.avatar ?
    `${ENV.SERVER_URL}${user?.profile?.avatar}` :
    ''
  };

  return (
    <Document title="Anonymous Chat" env={ENV} isDarkMode={isDarkMode}>
      <AuthProvider value={authContext}>
        <SocketProvider socket={socket}>
          <Outlet />
        </SocketProvider>
      </AuthProvider>
    </Document>);

};

export default withSentry(App);

export const ErrorBoundary = () => {
  const error = useRouteError();
  const { isDarkMode } = useDarkMode({
    defaultValue: true,
    initializeWithValue: false,
    localStorageKey: 'dark-mode'
  });

  // for intentionally thrown 4xx Responses
  if (isRouteErrorResponse(error)) {
    return (
      <Document title={error.statusText} isDarkMode={isDarkMode}>
        <div>
          <h1>
            {error.status} {error.statusText}
          </h1>
          <p>{error.data}</p>
        </div>
      </Document>);

  }

  if (error instanceof Error) {
    return (
      <Document title="Oops!" isDarkMode={isDarkMode}>
        <div className="error-boundary">
          <div className="error-boundary__status">500</div>
          <div className="error-boundary__message">Something went wrong</div>
          <Link className="error-boundary__link" to="/chats">
            Homepage
          </Link>
        </div>
      </Document>);

  }

  captureRemixErrorBoundaryError(error);

  return <h1>Unknown Error</h1>;
};

type DocumentProps = {
  children: ReactNode;
  title?: string;
  env?: Record<string, string>;
  isDarkMode?: boolean;
};

const Document = ({
  children,
  title,
  env,
  isDarkMode = true
}: DocumentProps) =>
<html lang="en" data-theme={isDarkMode ? 'dark' : 'light'}>
    <head>
      <meta charSet="utf-8" />
      <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

      {title ? <title>{title}</title> : null}
      <Meta />
      <Links />
      <meta name="emotion-insertion-point" content="emotion-insertion-point" />
    </head>
    <body>
      {children}
      <script
    // eslint-disable-next-line react/no-danger -- It's okay, this way we expose public ENV vars on window
    dangerouslySetInnerHTML={{
      __html: `window.ENV = ${JSON.stringify(env)}`
    }} />

      <ScrollRestoration />
      <Scripts />
      <LiveReload />
    </body>
  </html>;